import { TodoItemSuccess } from '../todo.item';

/**
 * Check if input value is type of TodoItemSuccess
 * @param value
 */
export const isTodoItemSuccess = (value: unknown): value is TodoItemSuccess => {
  return (
    !!value &&
    typeof value === 'object' &&
    'id' in value &&
    'type' in value &&
    value.type === 'success'
  );
};
