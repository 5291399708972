import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';

import { PatientAdmissionModel } from '~/api/patients/admissions/patient-admission.model';
import { InitialContactTodoModel } from '~/api/todo/model/initial-contact-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { formatDate } from '~/shared/utils/date-time';
import { parseDateTime } from '~/shared/utils/parse-date-time';

import { TodoItem, TodoMessage } from '../todo.item';
import {
  ExtendedInitialContactTodoModel,
  InitialContactAdmissionData,
} from '../types/initial-contact.todo';

/**
 * Class which maps initial contact TO-DOs
 */
export class InitialContactTodosResolver {
  private router = inject(Router);
  private translate = inject(CrmTranslateService);

  mapToExtendedTodo(
    todo: InitialContactTodoModel,
    admissions: PatientAdmissionModel[],
    users: UserModel[],
  ): ExtendedInitialContactTodoModel {
    const admission = admissions.find(({ _id }) => _id === todo.meta.admission);
    const user = users.find(({ _id }) => _id === todo.meta.user);

    return {
      ...todo,
      admission: admission && {
        id: admission._id,
        dischargeDate: parseDateTime(admission.dischargeDate),
      },
      user,
    };
  }

  mapToItem(
    todo: ExtendedInitialContactTodoModel,
  ): TodoItem<ExtendedInitialContactTodoModel> {
    const { admission, user, meta, _id, endTime } = todo;

    if (!admission || !user) {
      throw new Error(`No admission or user for todo '${todo._id}'`);
    }

    if (!admission.dischargeDate) {
      throw new Error(`No discharge date for admission '${admission.id}'!`);
    }

    return {
      id: _id,
      type: 'actual',
      user,
      icon: 'components-line',
      category: this.translate.get('todos.category.toc'),
      title: this.translate.get('todos.initialContact.title'),
      messages: this.resolveMessages(todo, admission),
      expireAt: parseDateTime(endTime),
      action: {
        id: `fill-form-${admission.id}`,
        title: 'todos.completeForm',
        icon: 'icons:document-3-line',
        size: 'small',
        type: 'primary',
        action: () => {
          this.router.navigate(
            [meta.organization, 'users', user._id, 'forms', 'new', 'call-log'],
            { queryParams: { admission: admission.id } },
          );
        },
      },
      model: todo,
    };
  }

  private resolveMessages(
    todo: ExtendedInitialContactTodoModel,
    admission: InitialContactAdmissionData,
  ): TodoMessage[] {
    const messages: TodoMessage[] = [];

    const days = this.getTocInitialWithinDaysByPriority(todo.priority);

    const message = this.translate.get('todos.initialContact.within', {
      count: days,
    });

    switch (days) {
      case 2:
        messages.push({ message });
        break;
      default:
        messages.push({ message, type: 'danger' });
        break;
    }

    messages.push({
      message: this.translate.get('todos.initialContact.dischargeDate', {
        date: formatDate(admission.dischargeDate),
      }),
    });

    return messages;
  }

  /**
   * Resolve initial contact days by priority
   *
   * @param priority
   * @private
   */
  private getTocInitialWithinDaysByPriority(priority: number) {
    switch (priority) {
      case 11:
      case 10:
        return 2;
      case 8:
      case 9:
        return 7;
      case 6:
      case 7:
        return 14;
      case 4:
      case 5:
        return 30;
      default:
        return 0;
    }
  }
}
