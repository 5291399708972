import { inject } from '@angular/core';
import { CrmTranslateService } from 'common-module/translate';
import { Duration } from 'luxon';

export const formatAfterExpirationFactory = () => {
  const ts = inject(CrmTranslateService);

  return (duration: Duration) => {
    const { weeks, days, hours, minutes, seconds } = duration;

    if (weeks > 0) {
      return ts.get('todos.expiration.after.week', { value: weeks });
    }

    if (days > 0) {
      return ts.get('todos.expiration.after.day', { value: days });
    }

    if (hours > 0) {
      return ts.get('todos.expiration.after.hour', { value: hours });
    }

    if (minutes > 0) {
      return ts.get('todos.expiration.after.minute', { value: minutes });
    }

    return ts.get('todos.expiration.after.second', { value: seconds });
  };
};
