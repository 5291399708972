import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmDictionary } from 'common-module/core/types';

import { ExtendedTodoModel, TodoItemActual } from './todo.item';
import { ExtendedInitialContactTodoModel } from './types/initial-contact.todo';

/**
 * Class which handles redirect by specific TO-DO
 */
export class TodoRedirect {
  private router = inject(Router);

  redirect(item: TodoItemActual<ExtendedTodoModel>) {
    switch (item.model.type) {
      case 'toc_initial_contact':
        return this.redirectToAdmissions(item.model);
      default:
        return this.makeRedirect(item.model.meta.organization, item.user._id);
    }
  }

  private redirectToAdmissions(model: ExtendedInitialContactTodoModel) {
    if (!model.user) {
      return;
    }

    this.makeRedirect(model.meta.organization, model.user._id, {
      'user-detail[tab]': '2',
      'health-history[tab]': '6',
    });
  }

  private makeRedirect(org: string, user: string, queryParams?: CrmDictionary) {
    this.router.navigate([org, 'users', user], { queryParams });
  }
}
