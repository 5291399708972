import { inject } from '@angular/core';
import { crmCreateModalFn$ } from 'common-module/modal';
import { CrmTranslateService } from 'common-module/translate';
import { CrmTranslateMessage } from 'common-module/translate';

import { TableRow } from '../../components/table/table.model';
import { TableProvider } from '../../components/table/table.provider';

import { TableSelectionModalComponent } from './table-selection-modal.component';

export const tableSelectionModalFactory = () => {
  const modal$ = crmCreateModalFn$();
  const translate = inject(CrmTranslateService);

  return <Row extends TableRow>({
    title,
    provider,
    action,
    confirm,
  }: {
    title: CrmTranslateMessage;
    confirm: CrmTranslateMessage;
    provider: TableProvider<Row>;
    action: (value: string[]) => void;
  }) =>
    modal$(
      TableSelectionModalComponent<Row>,
      { provider, confirm },
      { nzTitle: translate.get(title), nzWidth: '810px' },
    ).subscribe((value) => action(value));
};
