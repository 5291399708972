import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * Represents scheduled call to-do with specific metadata
 */
export type ScheduledCallTodoModel = BaseTodoModel & {
  type: 'scheduled_call';
  event: string;
  meta: { user: string; organization: string };
};

/**
 * Determines if input value is scheduled call to-do model
 *
 * @param value
 */
export const isScheduledCallTodo = (
  value: unknown,
): value is ScheduledCallTodoModel =>
  isTodoModel(value) && value.type === 'scheduled_call';
