import { Injector, signal, WritableSignal } from '@angular/core';
import { crmResolveExpression } from 'common-module/core';
import { CrmResolvable } from 'common-module/core/types';
import { CrmRenderItem } from 'common-module/renderer';
import { CrmTranslateMessage } from 'common-module/translate';
import { Observable, Subject, takeUntil } from 'rxjs';

export type RightSidebarData = {
  title?: CrmTranslateMessage;
  render: CrmRenderItem;
};

export type RightSidebarItemInputs = {
  title: WritableSignal<CrmTranslateMessage | undefined>;
  showBadge: WritableSignal<boolean>;
  destroy$: Observable<unknown>;
  injector: Injector;
};

export abstract class RightSidebarItemProvider {
  protected inputs!: RightSidebarItemInputs;

  setup(inputs: RightSidebarItemInputs): RightSidebarItemProvider {
    this.inputs = inputs;

    inputs.destroy$.subscribe(() => this.destroy());

    return this;
  }

  abstract init(): CrmResolvable<RightSidebarData>;
  abstract destroy(): void;
}

export abstract class RightSidebarItem {
  title = signal<CrmTranslateMessage | undefined>(undefined);
  render = signal<CrmRenderItem | undefined>(undefined);
  isLoading = signal(false);
  showBadge = signal(false);

  protected provider!: RightSidebarItemProvider;
  protected destroy$ = new Subject<void>();

  abstract key: string;
  abstract icon: string;
  abstract selectedIcon: string;

  constructor(injector: Injector) {
    this.provider = this.initProvider(injector).setup({
      title: this.title,
      showBadge: this.showBadge,
      destroy$: this.destroy$,
      injector,
    });
  }

  init() {
    this.isLoading.set(true);

    crmResolveExpression({ resolvable: this.provider.init() })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.title.set(data.title);
        this.render.set(data.render);
        this.isLoading.set(false);
      });
  }

  destroy() {
    this.destroy$.next();
  }

  protected abstract initProvider(injector: Injector): RightSidebarItemProvider;
}
