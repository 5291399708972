import { ExtendedTodoModel, TodoItemActual } from '../todo.item';

/**
 * Check if input value is type of TodoItemActual
 * @param value
 */
export const isTodoItemActual = <Value extends ExtendedTodoModel>(
  value: unknown,
): value is TodoItemActual<Value> => {
  return (
    !!value &&
    typeof value === 'object' &&
    'id' in value &&
    'type' in value &&
    value.type === 'actual'
  );
};
