import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';

import { AssessmentTodoModel } from '~/api/todo/model/assessment-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { parseDateTime } from '~/shared/utils/parse-date-time';
import { BaseTodoResolver } from '~/shared/crm/layout/right-sidebar/todos/resolvers/base-todo.resolver';

import { TodoItem } from '../todo.item';
import { ExtendedAssessmentTodoModel } from '../types/assessment.todo';

/**
 * Class which resolves and maps assessment TO-DOs
 */
export class AssessmentTodosResolver extends BaseTodoResolver {
  private router = inject(Router);
  private translate = inject(CrmTranslateService);

  mapToExtendedTodo(
    todo: AssessmentTodoModel,
    users: UserModel[],
  ): ExtendedAssessmentTodoModel {
    return {
      ...todo,
      user: users.find(({ _id }) => _id === todo.meta.user),
    };
  }

  mapToItem(
    todo: ExtendedAssessmentTodoModel,
  ): TodoItem<ExtendedAssessmentTodoModel> {
    const { user, _id, endTime, meta } = todo;

    if (!user) {
      throw new Error(`No user for todo '${_id}'`);
    }

    return {
      id: _id,
      type: 'actual',
      user,
      category: this.translate.get('todos.category.forms'),
      icon: 'document-3-line',
      expireAt: parseDateTime(endTime),
      messages: [
        { message: this.translate.get('todos.completeAssessment.message') },
      ],
      title: this.translate.get('todos.completeAssessment.title', {
        assessment: this.translate.get(
          `todos.completeAssessment.type.${meta.record.type}`,
        ),
      }),
      action: {
        id: `fill-assessment-${meta.record.type}`,
        title: `todos.completeForm`,
        icon: 'icons:document-3-line',
        size: 'small',
        type: 'primary',
        action: () => {
          this.router.navigate([
            meta.organization,
            'users',
            user._id,
            'forms',
            'new',
            meta.record.type,
          ]);
        },
      },
      dropdownActions: [this.getMarkAsNotApplicableAction(_id)],
      model: todo,
    };
  }
}
