import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * Represents TOC follow-up visit to-do with specific metadata
 */
export type TocFollowUpVisitTodoModel = BaseTodoModel & {
  type: 'toc_follow_up_visit';
  event: string;
  meta: {
    admission: string;
    dischargeDate: string;
    user: string;
    organization: string;
  };
};

/**
 * Determines if input value is toc follow-up visit to-do model
 *
 * @param value
 */
export const isTocFollowUpVisitTodoModel = (
  value: unknown,
): value is TocFollowUpVisitTodoModel =>
  isTodoModel(value) && value.type === 'toc_follow_up_visit';
