import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { CrmUnsubscribeDirective } from 'common-module/core';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { delay, of, takeUntil } from 'rxjs';

import { TextComponent } from '~/shared/components/typography/text.component';

import { TodoItemSuccess } from './todo.item';
import { TodosProvider } from './todos.provider';

@Component({
  standalone: true,
  selector: 'app-todo-success',
  template: `
    <div class="ehr-todo--success__icon">
      <span nz-icon nzType="icons:check-circle-line"></span>
    </div>
    <app-text [message]="item().message" size="small"></app-text>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TextComponent, NzIconDirective],
})
export class TodoSuccessComponent
  extends CrmUnsubscribeDirective
  implements OnInit
{
  @HostBinding('class')
  protected readonly hostClass = 'ehr-todo ehr-todo--success';

  item = input.required<TodoItemSuccess>();

  private provider = inject(TodosProvider);

  ngOnInit() {
    of(null)
      .pipe(delay(5000), takeUntil(this.destroyed$))
      .subscribe(() => this.provider.removeItem(this.item().id));
  }
}
