import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { CrmOption } from 'common-module/common';
import { crmFormRequiredValidator } from 'common-module/form';
import { CrmFormConfigOptions } from 'common-module/form/form.provider';
import { CrmFormFragment } from 'common-module/form/fragments';
import {
  CrmFormTextareaComponent,
  crmGetFormSelect,
} from 'common-module/form/inputs';
import { CrmTranslateService } from 'common-module/translate';
import { crmGetUserName } from 'common-module/user';
import { DateTime } from 'luxon';
import {
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { getAdmissionLabel } from '~/api/patients/admissions/get-admission-label';
import { PatientsAdmissionsApiService } from '~/api/patients/admissions/patients-admissions-api.service';
import {
  CommunityPatientType,
  CommunityServiceType,
} from '~/api/user/groups/user-community';
import { UserService } from '~/api/user/user.service';

import { getFormDate } from '../../components/date/get-form-date';
import {
  CustomForm,
  CustomFormProvider,
} from '../../form/custom-form.provider';
import { EventBusService } from '../../services/event-bus.service';
import { InterfaceToGroup } from '../../types/interface-to-group';
import { isPastDate } from '../../utils/date/is-past-date';

type PatientType = { id: string; type: CommunityPatientType };
type ServiceType = { id: string; type: CommunityServiceType };

type Data = {
  patientType: PatientType;
  serviceType: ServiceType;
  serviceType2: ServiceType;
  serviceType3: ServiceType;
  start: DateTime;
  duration: number;
  admission: string;
  disableAdmissionBusChange: true;
  description: string;
  providers: string[];
};

type InputData = {
  communityId: string;
  patientTypes: PatientType[];
  serviceTypes: ServiceType[];
  durations: number[];
  user: string;
  patient: string;
  title?: string;
  originalEvent?: string;
} & Partial<Data>;

@Injectable()
export class ScheduleVisitFormProvider extends CustomFormProvider<
  InputData,
  Data
> {
  private serviceType2 = { name: '' };
  private serviceTypes2 = signal<CrmOption<ServiceType>[]>([]);
  private serviceTypes2$ = toObservable(this.serviceTypes2);

  private serviceTypes3 = signal<CrmOption<ServiceType>[]>([]);
  private serviceTypes3$ = toObservable(this.serviceTypes3);

  private admissions = signal<CrmOption[]>([]);
  private admissions$ = toObservable(this.admissions);
  private defaultAdmission?: string;

  private translate = inject(CrmTranslateService);
  private admission = inject(PatientsAdmissionsApiService);
  private user = inject(UserService);
  private eventBus = inject(EventBusService);

  override formInitialized(form: CustomForm<Data>) {
    super.formInitialized(form);

    const {
      admission: admissionControl,
      serviceType,
      serviceType2,
      serviceType3,
    } = form.form.controls;

    combineLatest([
      this.initChildrenServiceType({
        parent: serviceType,
        child: serviceType2,
        options: this.serviceTypes2,
      }),
      this.initChildrenServiceType({
        parent: serviceType2,
        child: serviceType3,
        options: this.serviceTypes3,
        parentName: this.serviceType2,
      }),
      admissionControl.valueChanges.pipe(
        startWith(admissionControl.value),
        tap((admission) => {
          if (admission) {
            this.eventBus.emit({
              id: 'SCHEDULE_TELEHEALTH_VISIT:ADMISSION',
              data: admission,
            });
          }
        }),
      ),
      (() => {
        if (!this.data.disableAdmissionBusChange) {
          return this.eventBus
            .on<string | undefined>('FORM:ADMISSION', { remember: true })
            .pipe(
              tap((admission) => {
                if (admission) {
                  this.loadAdmissionIfMissing(admission);
                }

                this.defaultAdmission = admission;
                admissionControl.setValue(admission);
              }),
            );
        } else {
          this.defaultAdmission = this.data.admission;
          return of(true);
        }
      })(),
    ])
      .pipe(debounceTime(0), takeUntil(this.destroy$))
      .subscribe();
  }

  protected override getFormFragments({
    data,
  }: CrmFormConfigOptions<InputData>): Observable<CrmFormFragment[]> {
    return forkJoin({
      admissions: this.admission
        .listAllWithin60Days({ patient: data.patient })
        .pipe(
          switchMap((admissions) => {
            if (!data.admission) {
              return of(admissions);
            }

            const admissionsSet = new Set(admissions.map(({ _id }) => _id));

            if (admissionsSet.has(data.admission)) {
              return of(admissions);
            }

            return this.admission.get(data.admission).pipe(
              map((admission) => [admission, ...admissions]),
              catchError(() => of(admissions)),
            );
          }),
        ),
      providers: this.user.listAllNavigators(),
    }).pipe(
      map(({ admissions, providers }) => [
        crmGetFormSelect({
          name: 'patientType',
          config: {
            label: 'scheduleVisit.patientType',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            options: data.patientTypes.map((value) => ({
              value: value,
              label: value.type.name,
            })),
          },
        }),
        crmGetFormSelect({
          name: 'serviceType',
          config: {
            label: 'scheduleVisit.visitType',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            options: data.serviceTypes.map((value) => ({
              value: value,
              label: value.type.name,
            })),
          },
        }),
        crmGetFormSelect({
          name: 'serviceType2',
          config: {
            label: {
              message: 'scheduleVisit.visitTypeSub',
              context: { name: this.translate.get('scheduleVisit.visitType') },
            },
            validators: [crmFormRequiredValidator()],
          },
          options: { options: this.serviceTypes2$ },
          visible$: () =>
            this.serviceTypes2$.pipe(map((options) => !!options.length)),
        }),
        crmGetFormSelect({
          name: 'serviceType3',
          config: {
            label: {
              message: 'scheduleVisit.visitTypeSub',
              context: this.serviceType2,
            },
            validators: [crmFormRequiredValidator()],
          },
          options: { options: this.serviceTypes3$ },
          visible$: () =>
            this.serviceTypes3$.pipe(map((options) => !!options.length)),
        }),
        crmGetFormSelect({
          name: 'admission',
          config: {
            label: 'scheduleVisit.admission',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            options: (() => {
              this.admissions.set(
                admissions.map((model) => ({
                  label: getAdmissionLabel(model),
                  value: model._id,
                })),
              );

              return this.admissions$;
            })(),
          },
          visible$: (form: FormGroup<InterfaceToGroup<Data>>) =>
            form.controls.serviceType2.valueChanges.pipe(
              startWith(form.controls.serviceType2.value),
              map(
                (value) =>
                  value?.type?.name === 'Hospital Discharge/Transition of Care',
              ),
              tap((visible) => {
                form.controls.admission.setValue(
                  visible
                    ? (form.controls.admission.value ?? this.defaultAdmission)
                    : null,
                );
              }),
            ),
        }),
        getFormDate({
          name: 'start',
          config: {
            label: 'scheduleVisit.start',
            validators: [
              crmFormRequiredValidator(),
              {
                name: 'past',
                error: 'scheduleVisit.error.pastStart',
                validator: (control) => {
                  const value = control.value as DateTime | undefined;

                  if (!value) {
                    return null;
                  }

                  return value < DateTime.now() ? { past: true } : null;
                },
              },
            ],
          },
          layout: { span: 16 },
          options: {
            format: 'MMM dd yyyy hh:mm a',
            disabledDate: isPastDate,
            showTime: {
              nzFormat: 'hh:mm a',
              nzUse12Hours: true,
              nzMinuteStep: 15,
            },
          },
        }),
        crmGetFormSelect({
          name: 'duration',
          config: {
            label: 'scheduleVisit.duration',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            options: data.durations.map((duration) => ({
              label: this.translate.get({
                message: 'scheduleVisit.minutes',
                context: { value: duration },
              }),
              value: duration,
            })),
          },
          layout: { span: 8 },
        }),
        crmGetFormSelect({
          name: 'providers',
          config: {
            label: 'scheduleVisit.invitations',
            validators: [crmFormRequiredValidator()],
          },
          options: {
            mode: 'multiple',
            options: providers.map((user) => ({
              value: user._id,
              label: crmGetUserName(user),
            })),
          },
        }),
        {
          type: 'data-input',
          name: 'description',
          content: CrmFormTextareaComponent,
          contentContext: {
            config: { label: 'scheduleVisit.description' },
          },
        },
      ]),
    );
  }

  private initChildrenServiceType({
    parent,
    child,
    options,
    parentName,
  }: {
    parent: FormGroup<InterfaceToGroup<ServiceType>>;
    child: FormGroup<InterfaceToGroup<ServiceType>>;
    options: WritableSignal<CrmOption<ServiceType>[]>;
    parentName?: { name: string };
  }) {
    let subTypeReset = false;

    return parent.valueChanges.pipe(
      startWith(parent.value),
      filter(() => !this.isLoading),
      tap((value) => {
        const subTypes = (value?.type?.children ?? []).map((child, index) => ({
          label: child.name,
          value: { id: String(index), type: child },
        }));

        options.set(subTypes);

        if (parentName) {
          parentName.name = value?.type?.name ?? '';
        }

        if (subTypeReset) {
          child.reset();
        } else {
          subTypeReset = true;
        }
      }),
    );
  }

  private loadAdmissionIfMissing(id: string) {
    if (this.admissions().some(({ value }) => id === value)) {
      return;
    }

    this.admission
      .get(id)
      .pipe(catchError(() => EMPTY))
      .subscribe((admission) => {
        this.admissions.update((state) => [
          { label: getAdmissionLabel(admission), value: admission._id },
          ...state,
        ]);
      });
  }
}
