import { CocmScheduleSessionTodoModel } from './cocm-schedule-session.model';
import { CocmTreatmentPlanTodoModel } from './cocm-treatment-plan-todo.model';
import { InitialContactTodoModel } from './initial-contact-todo.model';
import { AssessmentTodoModel } from './assessment-todo.model';
import { RescheduleTelehealthVisitTodoModel } from './reschedule-telehealth-visit-todo.model';
import { ScheduledCallTodoModel } from './scheduled-call-todo.model';
import { TocFollowUpVisitTodoModel } from './toc-follow-up-visit-todo.model';

/**
 * Represents TO-DO status with additional properties
 */
type TodoByStatus =
  | { status: 'active' }
  | { status: 'rejected'; meta: { rejectReason: string } };

/**
 * Represents BE TO-DO model
 *
 * @prop _id - unique identifier
 * @prop tags - array of tags
 * @prop assignee - assigned user
 * @prop priority - to-do priority
 * @prop startTime - ISO Date of to-do start
 * @prop endTime - ISO Date of to-do end
 * @prop meta - metadata of to-do
 */
export type BaseTodoModel = {
  _id: string;
  tags: string[];
  priority: number;
  assignee: string;
  startTime: string;
  endTime: string;
  path: string;
} & TodoByStatus;

/**
 * Represents any to-do model
 */
export type TodoModel =
  | InitialContactTodoModel
  | TocFollowUpVisitTodoModel
  | CocmTreatmentPlanTodoModel
  | RescheduleTelehealthVisitTodoModel
  | CocmScheduleSessionTodoModel
  | ScheduledCallTodoModel
  | AssessmentTodoModel;

/**
 * Determines if input value is to-do model type
 *
 * @param value
 */
export const isTodoModel = (value: unknown): value is TodoModel => {
  return !!value && typeof value === 'object' && 'type' in value;
};
