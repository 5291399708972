import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * Represents CoCM treatment plan to-do with specific metadata
 */
export type CocmTreatmentPlanTodoModel = BaseTodoModel & {
  type: 'cocm_care_plan_bh';
  meta: {
    user: string;
    program: string;
    organization: string;
  };
};

/**
 * Determines if input value is CoCM treatment plan to-do model
 *
 * @param value
 */
export const isCocmTreatmentPlanTodo = (
  value: unknown,
): value is CocmTreatmentPlanTodoModel =>
  isTodoModel(value) && value.type === 'cocm_care_plan_bh';
