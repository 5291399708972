import { Pipe, PipeTransform } from '@angular/core';

import { ExtendedTodoModel } from '../todo.item';
import { isTodoItemActual } from '../utils/is-todo-item-actual';

@Pipe({ name: 'isTodoItemActual', standalone: true })
export class IsTodoItemActualPipe implements PipeTransform {
  transform<Model extends ExtendedTodoModel>(value: unknown) {
    return isTodoItemActual<Model>(value) ? value : undefined;
  }
}
