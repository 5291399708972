import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';
import { DateTime } from 'luxon';

import { EventModel } from '~/api/events/event.model';
import { ScheduledCallTodoModel } from '~/api/todo/model/scheduled-call-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { parseDateTime } from '~/shared/utils/parse-date-time';
import { BaseTodoResolver } from '~/shared/crm/layout/right-sidebar/todos/resolvers/base-todo.resolver';
import { formatTime } from '~/shared/utils/date-time';

import { formatAfterExpirationFactory } from '../factories/format-after-expiration.factory';
import { formatBeforeExpirationFactory } from '../factories/format-before-expiration.factory';
import { TodoItem } from '../todo.item';
import { ExtendedScheduledCallTodoModel } from '../types/scheduled-call.todo';

/**
 * Class which maps initial contact TO-DOs
 */
export class ScheduledCallTodosResolver extends BaseTodoResolver {
  private router = inject(Router);
  private translate = inject(CrmTranslateService);
  private formatBeforeExpiration = formatBeforeExpirationFactory();
  private formatAfterExpiration = formatAfterExpirationFactory();

  mapToExtendedTodo(
    todo: ScheduledCallTodoModel,
    users: UserModel[],
    events: EventModel[],
  ): ExtendedScheduledCallTodoModel {
    const user = users.find(({ _id }) => _id === todo.meta.user);
    const event = events.find(({ _id }) => _id === todo.event);

    return { ...todo, user, event };
  }

  mapToItem(
    todo: ExtendedScheduledCallTodoModel,
  ): TodoItem<ExtendedScheduledCallTodoModel> {
    const { event, user, meta, _id, endTime } = todo;

    if (!event || !user) {
      throw new Error(`No event or user for todo '${_id}'`);
    }

    const html = document.createElement('div');
    html.innerHTML = event.text ?? '';

    return {
      id: _id,
      type: 'actual',
      user,
      icon: 'phone-line',
      category: this.translate.get('todos.category.call'),
      title: this.translate.get('todos.scheduledCall.title'),
      messages: [{ message: html.innerText }],
      expireAt: parseDateTime(endTime),
      resolveStatus: ({ expireAt }) =>
        expireAt < DateTime.now() ? 'overdue' : 'active',
      resolveExpiration: ({ expireAt }) => {
        const now = DateTime.now();

        if (
          expireAt.startOf('day').toMillis() === now.startOf('day').toMillis()
        ) {
          return this.translate.get('todos.expiration.at', {
            date: formatTime(expireAt),
          });
        }

        if (expireAt > now) {
          return this.formatBeforeExpiration(
            expireAt.diff(now, [
              'weeks',
              'days',
              'hours',
              'minutes',
              'seconds',
              'milliseconds',
            ]),
          );
        }

        return this.formatAfterExpiration(
          now.diff(expireAt, [
            'weeks',
            'days',
            'hours',
            'minutes',
            'seconds',
            'milliseconds',
          ]),
        );
      },
      action: {
        id: `fill-form-${_id}`,
        title: 'todos.completeForm',
        icon: 'icons:document-3-line',
        size: 'small',
        type: 'primary',
        action: () => {
          this.router.navigate(
            [meta.organization, 'users', user._id, 'forms', 'new', 'call-log'],
            { queryParams: { todo: _id } },
          );
        },
      },
      dropdownActions: [this.getMarkAsCompletedAction(_id)],
      model: todo,
    };
  }
}
