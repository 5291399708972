import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';

import { CocmTreatmentPlanTodoModel } from '~/api/todo/model/cocm-treatment-plan-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { parseDateTime } from '~/shared/utils/parse-date-time';

import { TodoItem } from '../todo.item';
import { ExtendedCocmTreatmentPlanTodoModel } from '../types/cocm-treatment-plan.todo';

/**
 * Class which maps cocm treatment plan TO-DOs
 */
export class CocmTreatmentPlanTodosResolver {
  private router = inject(Router);
  private translate = inject(CrmTranslateService);

  mapToExtendedTodo(
    todo: CocmTreatmentPlanTodoModel,
    users: UserModel[],
  ): ExtendedCocmTreatmentPlanTodoModel {
    const user = users.find(({ _id }) => _id === todo.meta.user);

    return { ...todo, user };
  }

  mapToItem(
    todo: ExtendedCocmTreatmentPlanTodoModel,
  ): TodoItem<ExtendedCocmTreatmentPlanTodoModel> {
    const { user, meta, _id, endTime } = todo;

    if (!user) {
      throw new Error(`No user for todo '${todo._id}'`);
    }

    return {
      id: _id,
      type: 'actual',
      user,
      icon: 'document-3-line',
      category: this.translate.get('todos.category.forms'),
      title: this.translate.get('todos.cocmTreatmentPlan.title'),
      messages: [
        { message: this.translate.get('todos.cocmTreatmentPlan.message') },
      ],
      expireAt: parseDateTime(endTime),
      action: {
        id: `fill-form-care-plan-bh`,
        title: 'todos.createForm',
        icon: 'icons:document-3-line',
        size: 'small',
        type: 'primary',
        action: () => {
          this.router.navigate([
            meta.organization,
            'users',
            user._id,
            'forms',
            'new',
            'care-plan-bh',
          ]);
        },
      },
      model: todo,
    };
  }
}
