import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * All CoCM schedule session TO-DO types
 */
export const scheduleSessionTodoTypes = [
  'cocm_schedule_therapy_session',
  'cocm_schedule_psychiatric_session',
] as const;

/**
 * Schedule session types as TS type
 */
export type ScheduleSessionTodoType = (typeof scheduleSessionTodoTypes)[number];

/**
 * Represents CoCM schedule session to-do with specific metadata
 */
export type CocmScheduleSessionTodoModel = BaseTodoModel & {
  type: ScheduleSessionTodoType;
  meta: {
    record: string;
    user: string;
    organization: string;
  };
};

/**
 * Determines if input value is CoCM schedule session to-do model
 *
 * @param value
 */
export const isCocmScheduleSessionTodo = (
  value: unknown,
): value is CocmScheduleSessionTodoModel =>
  isTodoModel(value) && scheduleSessionTodoTypes.some((t) => t === value.type);
