import {
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { CrmUnsubscribeDirective } from 'common-module/core';
import { distinctUntilChanged, filter, fromEvent } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  standalone: true,
  selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective
  extends CrmUnsubscribeDirective
  implements OnInit
{
  @Output() public endScroll$ = new EventEmitter();

  protected readonly el = inject(ElementRef);

  protected end = false;

  public ngOnInit() {
    fromEvent<Event>(this.el.nativeElement, 'scroll')
      .pipe(
        map((event) => event.target as HTMLElement),
        map((event) => {
          const { scrollHeight, offsetHeight, scrollTop } = event;
          this.end = scrollHeight <= offsetHeight + scrollTop + 10;
          return this.end;
        }),
        distinctUntilChanged(),
        filter(() => this.end),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.endScroll$.emit();
      });
  }
}
