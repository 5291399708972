import { Pipe, PipeTransform } from '@angular/core';

import { isTodoItemSuccess } from '../utils/is-todo-item-success';

@Pipe({ name: 'isTodoItemSuccess', standalone: true })
export class IsTodoItemSuccessPipe implements PipeTransform {
  transform(value: unknown) {
    return isTodoItemSuccess(value) ? value : undefined;
  }
}
