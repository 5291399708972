import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CrmOption } from 'common-module/common';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';

import { AvatarComponent } from '~/shared/components/avatar/avatar.component';
import { TitleComponent } from '~/shared/components/typography/title.component';
import { resolveUserAvatarUrl } from '~/shared/utils/media/resolve-user-avatar-url';

import { TodoCategory } from './todo.category';
import { TodosProvider } from './todos.provider';

@Component({
  standalone: true,
  selector: 'app-todos-header',
  template: `
    <app-title [message]="title()" level="4" weight="bold"></app-title>
    <nz-select
      nzSize="small"
      nzPlacement="bottomRight"
      nzDropdownClassName="ehr-todos__filter"
      [nzDropdownMatchSelectWidth]="false"
      [nzCustomTemplate]="selectTmpl"
      [ngModel]="category()"
      (ngModelChange)="setCategory($event)"
    >
      @for (category of categoryOptions; track category.value) {
        <nz-option
          [nzValue]="category.value"
          [nzLabel]="category.label | crmTranslate"
          nzCustomContent
        >
          <span
            nz-icon
            [nzType]="icons[category.value]"
            nzTheme="outline"
          ></span>
          {{ category.label | crmTranslate }}
        </nz-option>
      }
    </nz-select>

    @if (userOption(); as userOption) {
      <div class="ehr-todos__header__user">
        <span>{{ 'todos.filter.for' | crmTranslate }}:</span>
        <span
          class="ehr-todos__header__option ehr-todos__header__option--user"
          [class.ehr-todos__header__option--selected]="userOption.id === user()"
          (click)="setUser(userOption.id)"
        >
          <app-avatar [src]="userOption.avatar" [size]="16"></app-avatar>
          <span>{{ userOption.name }}</span>
        </span>
        <span
          class="ehr-todos__header__option ehr-todos__header__option--all"
          [class.ehr-todos__header__option--selected]="userOption.id !== user()"
          (click)="setUser(undefined)"
        >
          {{ 'todos.filter.allPatients' | crmTranslate }}
        </span>
      </div>
    }

    <ng-template #selectTmpl let-selected>
      <span
        nz-icon
        [nzType]="$any(icons)[selected.nzValue]"
        nzTheme="outline"
      ></span>
      {{ selected.nzLabel }}
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TitleComponent,
    NzSelectComponent,
    FormsModule,
    NzOptionComponent,
    CrmTranslatePipe,
    NzIconDirective,
    AvatarComponent,
  ],
})
export class TodosHeaderComponent {
  @HostBinding('class')
  protected readonly hostClass = 'ehr-todos__header';

  private provider = inject(TodosProvider);

  protected icons: Record<TodoCategory, string> = {
    all: 'icons:color-filter-line',
    toc: 'icons:components-line',
    'schedule-thv': 'icons:schedule-line',
    call: 'icons:phone-line',
    'join-thv': 'icons:telehealth',
    forms: 'icons:document-3-line',
  };

  protected categoryOptions: CrmOption<TodoCategory>[] = [
    { value: 'all', label: 'generic.all' },
    { value: 'toc', label: 'todos.category.toc' },
    { value: 'schedule-thv', label: 'todos.category.schedule-thv' },
    { value: 'call', label: 'todos.category.call' },
    { value: 'join-thv', label: 'todos.category.join-thv' },
    { value: 'forms', label: 'todos.category.forms' },
  ];

  protected category = computed(() => this.provider.filter().category);
  protected user = computed(() => this.provider.filter().user);

  protected userOption = computed(() => {
    const user = this.provider.filterOptions().user;

    if (!user) {
      return undefined;
    }

    return { ...user, avatar: resolveUserAvatarUrl(user.id) };
  });

  protected title = computed(() => {
    return {
      message: 'todos.title',
      context: { count: this.provider.total() ?? '-' },
    };
  });

  protected setCategory(category: TodoCategory) {
    this.provider.setCategoryFilter(category);
  }

  protected setUser(user?: string) {
    this.provider.setUserFilter(user);
  }
}
