import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * Represents initial contact to-do with specific metadata
 */
export type InitialContactTodoModel = BaseTodoModel & {
  type: 'toc_initial_contact';
  meta: {
    startOfSeries: string;
    admission: string;
    user: string;
    organization: string;
  };
};

/**
 * Determines if input value is initial contact to-do model
 *
 * @param value
 */
export const isInitialContactTodo = (
  value: unknown,
): value is InitialContactTodoModel =>
  isTodoModel(value) && value.type === 'toc_initial_contact';
