import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * Represents reschedule telehealth visit to-do with specific metadata
 */
export type RescheduleTelehealthVisitTodoModel = BaseTodoModel & {
  type: 'reschedule_telehealth_visit';
  event: string;
  organization: string;
  meta: {
    admission?: string;
    user: string;
    organization: string;
    eventStartTime: string;
  };
};

/**
 * Determines if input value is reschedule telehealth visit to-do model
 *
 * @param value
 */
export const isRescheduleTelehealthVisitTodoModel = (
  value: unknown,
): value is RescheduleTelehealthVisitTodoModel =>
  isTodoModel(value) && value.type === 'reschedule_telehealth_visit';
