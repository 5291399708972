import { inject } from '@angular/core';
import { CrmMessageService } from 'common-module/message';
import { crmCreateModalFormFn$ } from 'common-module/modal';
import { ModalOptions } from 'ng-zorro-antd/modal';
import { EMPTY, switchMap } from 'rxjs';

import { OrganizationService } from '~/api/organization/organization.service';

import { ScheduleVisitFormProvider } from './schedule-visit-form.provider';
import { ScheduleVisitProvider } from './schedule-visit.provider';

/**
 * Factory function to get open schedule visit function
 */
export const openScheduleVisitFn = () => {
  const modal = crmCreateModalFormFn$();
  const organization = inject(OrganizationService);
  const message = inject(CrmMessageService);

  return (
    data: Omit<
      ScheduleVisitProvider['data'],
      'communityId' | 'patientTypes' | 'serviceTypes' | 'durations'
    >,
    settings?: ModalOptions,
  ) =>
    organization.getDefaultCommunity().pipe(
      switchMap((community) => {
        if (!community) {
          message.error('scheduleVisit.missingDefaultCommunity');
          return EMPTY;
        }

        const { settings: communitySettings } = community;

        if (
          !communitySettings ||
          !communitySettings.patientTypes ||
          !communitySettings.serviceTypes ||
          !communitySettings.telehealthVisitDurations
        ) {
          message.error('scheduleVisit.missingData');
          return EMPTY;
        }

        const patientTypes = communitySettings.patientTypes.map(
          (type, index) => ({
            id: String(index),
            type,
          }),
        );

        const serviceTypes = communitySettings.serviceTypes.map(
          (type, index) => ({
            id: String(index),
            type,
          }),
        );

        const patientType = data.patientType
          ? patientTypes.find(
              ({ type }) => type.name === data.patientType?.type?.name,
            )
          : undefined;

        const serviceType = data.serviceType
          ? serviceTypes.find(
              ({ type }) => type.name === data.serviceType?.type?.name,
            )
          : undefined;

        const serviceType2Child = data.serviceType2
          ? serviceType?.type.children?.findIndex(
              ({ name }) => name === data.serviceType2?.type?.name,
            )
          : undefined;

        const serviceType2 =
          serviceType2Child != null
            ? {
                id: String(serviceType2Child),
                type: serviceType!.type!.children![serviceType2Child],
              }
            : undefined;

        const serviceType3Child = data.serviceType3
          ? serviceType2?.type.children?.findIndex(
              ({ name }) => name === data.serviceType3?.type?.name,
            )
          : undefined;

        const serviceType3 =
          serviceType3Child != null
            ? {
                id: String(serviceType3Child),
                type: serviceType2!.type!.children![serviceType3Child],
              }
            : undefined;

        return modal({
          title: data.title ?? 'scheduleVisit.title',
          formProvider: ScheduleVisitFormProvider,
          modalProvider: ScheduleVisitProvider,
          data: {
            ...data,
            communityId: community._id,
            patientTypes,
            serviceTypes,
            durations: communitySettings.telehealthVisitDurations,
            patientType,
            serviceType,
            serviceType2,
            serviceType3,
          },
          settings,
        });
      }),
    );
};
