import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  Injector,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  CrmActionButton,
  CrmActionButtonComponent,
  CrmDropdownButtonComponent,
  crmResolveAction,
} from 'common-module/buttons';
import { crmKillEvent } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { crmGetUserName } from 'common-module/user';
import { DateTime } from 'luxon';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { interval, map } from 'rxjs';

import { AvatarComponent } from '~/shared/components/avatar/avatar.component';
import { TextComponent } from '~/shared/components/typography/text.component';
import { TitleComponent } from '~/shared/components/typography/title.component';
import { resolveUserAvatarUrl } from '~/shared/utils/media/resolve-user-avatar-url';

import { formatBeforeExpirationFactory } from './factories/format-before-expiration.factory';
import { ExtendedTodoModel, TodoItemActual, TodoMessage } from './todo.item';

@Component({
  standalone: true,
  selector: 'app-todo',
  template: `
    @if (vm(); as vm) {
      <div class="ehr-todo__header">
        <div class="ehr-todo__category">
          <span nz-icon [nzType]="'icons:' + vm.icon"></span>
          {{ vm.category }}
        </div>

        <div class="ehr-todo__expiration ehr-todo__expiration--{{ vm.status }}">
          <span nz-icon nzType="icons:time-line"></span>
          <app-text
            [text]="vm.expiration"
            textType="text"
            size="small"
          ></app-text>
        </div>

        @if (vm.dropdownActions; as actions) {
          <div class="ehr-todo__actions">
            <crm-dropdown-button
              [actions]="actions"
              size="small"
              type="text"
              moreIcon="icons:more-2-line"
            ></crm-dropdown-button>
          </div>
        }
      </div>

      <div class="ehr-todo__title">
        <app-text [text]="vm.title" weight="bold" textType="text"></app-text>
      </div>

      @for (msg of vm.messages; track $index) {
        <app-text
          [text]="msg.message"
          [type]="msg.type"
          size="small"
        ></app-text>
      }

      <div class="ehr-todo__footer">
        <div class="ehr-todo__user">
          <app-avatar
            [size]="16"
            [src]="vm.avatar"
            [centered]="true"
          ></app-avatar>
          <span>{{ vm.user }}</span>
        </div>

        @if (vm.action) {
          <div class="ehr-todo__action">
            <crm-action-button
              [action]="vm.action"
              (click)="handleActionClick($event, vm.action)"
            ></crm-action-button>
          </div>
        }
      </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TitleComponent,
    NzIconDirective,
    TextComponent,
    CrmTranslatePipe,
    CrmActionButtonComponent,
    AvatarComponent,
    CrmDropdownButtonComponent,
  ],
})
export class TodoComponent implements OnInit {
  @HostBinding('class')
  protected readonly hostClass = 'ehr-todo';

  item = input.required<TodoItemActual<ExtendedTodoModel>>();

  protected vm!: Signal<{
    icon: string;
    category: string;
    avatar: string;
    user: string;
    title: string;
    messages: TodoMessage[];
    expiration: string;
    status: 'active' | 'overdue';
    action?: CrmActionButton<TodoItemActual<ExtendedTodoModel>>;
    dropdownActions?: CrmActionButton<TodoItemActual<ExtendedTodoModel>>[];
  }>;

  private intervalData!: Signal<{
    expiration: string;
    status: 'active' | 'overdue';
    isActionVisible: boolean;
  }>;

  private injector = inject(Injector);
  private formatBeforeExpiration = formatBeforeExpirationFactory();

  ngOnInit() {
    const {
      action,
      icon,
      category,
      isActionVisible,
      title,
      messages,
      user,
      expireAt,
      resolveStatus,
      resolveExpiration,
      dropdownActions,
    } = this.item();

    this.intervalData = toSignal(
      interval(1000).pipe(
        map(() => ({
          expiration:
            resolveExpiration?.(this.item()) ??
            this.resolveExpiration(expireAt),
          status: resolveStatus?.(this.item()) ?? 'active',
          isActionVisible: isActionVisible?.(this.item()) ?? true,
        })),
      ),
      {
        injector: this.injector,
        initialValue: {
          expiration:
            resolveExpiration?.(this.item()) ??
            this.resolveExpiration(expireAt),
          status: resolveStatus?.(this.item()) ?? 'active',
          isActionVisible: isActionVisible?.(this.item()) ?? true,
        },
      },
    );

    this.vm = computed(() => {
      const { expiration, status, isActionVisible } = this.intervalData();

      return {
        category,
        icon,
        avatar: resolveUserAvatarUrl(user._id),
        user: crmGetUserName(user),
        title,
        messages,
        expiration,
        status,
        action: isActionVisible ? action : undefined,
        dropdownActions,
      };
    });
  }

  protected handleActionClick(
    event: MouseEvent,
    action: CrmActionButton<TodoItemActual<ExtendedTodoModel>>,
  ) {
    crmKillEvent(event);
    crmResolveAction(action, this.item());
  }

  private resolveExpiration(expireAt: DateTime) {
    const duration = expireAt.diffNow([
      'weeks',
      'days',
      'hours',
      'minutes',
      'seconds',
      'millisecond',
    ]);

    return this.formatBeforeExpiration(duration);
  }
}
