import { Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';

import { UpdateTodoDto } from '~/api/todo/dto/update-todo.dto';

import { TodoModel } from './model/todo.model';

@Injectable({ providedIn: 'root' })
export class TodoApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'todos',
  })
  protected readonly endpoint!: CrmEndpoint<TodoModel>;

  update(id: string, body: UpdateTodoDto) {
    return this.endpoint.update(id, body);
  }

  list(params: CrmDictionary) {
    return this.endpoint.list({ params });
  }
}
